# @owner @Appboy/messaging-and-automation

import liquidTemplateConstants from 'lib/constants/liquid-template-constants'
import { EmailValidator as emailValidator } from 'lib/email-validator'
import 'jquery'

export class Validator

  # This regex lifted from the main parsley code, with other protocols removed
  HTTP_REGEX = /https?:\/\/.+\..+/i

  HTTPS_REGEX = /https:\/\/.+\..+/i

  #check if ipv4, CIDR, ipv6, or ipv6-CIDR (source for regex: http://blog.markhatton.co.uk/2011/03/15/regular-expressions-for-ip-addresses-cidr-ranges-and-hostnames/)
  IPV4_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
  CIDR_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/
  IPV6_REGEX = /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*/
  IPV6_CIDR_REGEX = /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/(d|dd|1[0-1]d|12[0-8]))$/

  URL_REGEX = /(https?|ftp):\/\/.+\..+/i
  PROTOCOL_URL_IS_HTTP_OR_FTP_REGEX = /(https?|ftp):\/\//
  ENGAGEMENT_REPORT_REGEX = /^[a-zA-Z0-9\_\-]+$/

  @isValidHttp: (value) ->
    value = value.trim()
    return '' != value && HTTP_REGEX.test(value)

  @isValidHttps: (value) ->
    value = value.trim()
    return '' != value && HTTPS_REGEX.test(value)

  @isValidIP: (value) ->
    value = value.trim()
    return '' != value && (IPV4_REGEX.test(value) || CIDR_REGEX.test(value) || IPV6_REGEX.test(value) || IPV6_CIDR_REGEX.test(value))

  @isValidProtocolUrl: (value) ->
    value = value.trim()
    # If the URL starts with https?|ftp, then validate against the urlRegex
    if PROTOCOL_URL_IS_HTTP_OR_FTP_REGEX.test(value)
      return '' != value && URL_REGEX.test(value)

    # Otherwise, test against any arbitrary protocol URL such as foo://123
    return '' != value && /(.+?):\/\/.+$/i.test(value)

  @isValidDeepLink: (value) ->
    value = value.trim()
    # Test against any arbitrary protocol URL such as foo://123 or mailto:test@braze.com
    return '' != value && /(.+?):.+$/i.test(value)

  @isValidEmail: (value) ->
    return emailValidator.isValidEmail(value)

  @isValidEmailWithLiquid: (value) ->
    if !value.match(liquidTemplateConstants.LIQUID_REGEXES.TAG_OR_OBJECT)
      return @isValidEmail(value)
    return true

  @liquidWithoutApiTriggeredProperties: (value) ->
    if value.match(liquidTemplateConstants.TEMPLATE_VARIABLE_REGEXES.API_TRIGGER_EVENT_PROPERTIES)
      return false
    return true

  @liquidWithoutSmsInboundProperties: (value) ->
    if value.match(liquidTemplateConstants.TEMPLATE_VARIABLE_REGEXES.SMS_PROPERTIES)
      return false
    return true

  @liquidWithoutWhatsAppInboundProperties: (value) ->
    if value.match(liquidTemplateConstants.TEMPLATE_VARIABLE_REGEXES.WHATS_APP_PROPERTIES)
      return false
    return true

  @liquidWithoutCanvasProperties: (value) ->
    if value.match(liquidTemplateConstants.TEMPLATE_VARIABLE_REGEXES.CANVAS_PROPERTIES)
      return false
    return true

  @liquidWithoutEventProperties: (value) ->
    if value.match(liquidTemplateConstants.TEMPLATE_VARIABLE_REGEXES.EVENT_PROPERTIES)
      return false
    return true

  @isValidNavigationUri: (value) ->
    value = value.trim()
    if value.indexOf("/") != 0
      return false
    if value.indexOf(".xaml", value.length - ".xaml".length) == -1
      return false
    return true

  @isValidEngagementReportName: (value) ->
    value = value.trim()
    return '' != value && ENGAGEMENT_REPORT_REGEX.test(value)
